import React from "react";
import {Segment} from "semantic-ui-react";
import styles from "./defaultsegment.module.css";

const DefaultSegment = ({ children }) => (
    <Segment className={styles.main}>
        {children}
    </Segment>
);

export default DefaultSegment
import React from "react";
import '../../styles/semantic.min.css';
import {Breadcrumb, Container, Icon, Image} from "semantic-ui-react";
import LayoutContainer from "../../container/LayoutContainer";
import SEO from "../../components/seo/Seo";
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import {Link} from "@reach/router";
import DefaultSegment from "../../components/utils/DefaultSegment";
import PageHeader from "../../components/utils/PageHeader";
import groupPic from "../../assets/besonderhaut/besonderhaut.jpg";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import DesktopContainer from "../../container/DesktopContainer";
import layout from "../../templates/layout.module.css";

export default () => <LayoutContainer>
    <SEO title={'BesonderHaut'}/>
    <MobileTabletContainer>
        <MainNavigation/>
        <Container>
            <PageHeader title={'BesonderHaut'}/>
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Section><Link to={'/'}><Icon name={'home'}/>Start</Link></Breadcrumb.Section>
                    <Breadcrumb.Divider/>
                    <Breadcrumb.Section><Link to={'/was-wir-tun/'}>Was wir tun</Link></Breadcrumb.Section>
                    <Breadcrumb.Divider/>
                    <Breadcrumb.Section active>BesonderHaut</Breadcrumb.Section>
                </Breadcrumb>
                <DefaultSegment>
                    <Container>
                        <Image src={groupPic} centered/>
                        <p>Am 05.09.2017 gab die Schauspielerin Veronica Ferres gemeinsam mit dem Vorstand der DSK, Frau
                            Dr. Nicole Inselkammer, sowie Prof. Dr. Kathrin Giehl, Oberärztin und Leiterin des Zentrums
                            für seltene und genetische Hautkrankheiten am Klinikum der Universität München, den
                            Startschuss für das Hilfsprojekt BesonderHaut. Ebenfalls dabei war Christina Jäger, neben
                            Prof. Giehl Co-Initiatorin des Projektes.</p>
                        <blockquote><p>"Wir wollen Kindern mit seltener Hauterkrankung wieder ein Lächeln schenken"</p>
                            <cite>Dr. Nicole Inselkammer, Stiftungsgründerin und Vorstandsvorsitzende der DSK</cite>
                        </blockquote>
                        <p>Durch die Implementierung von BesonderHaut in die Stiftungsarbeit erweitert die DSK die
                            Unterstützung von Kindern mit seltenen und genetischen Hautkrankheiten nun auch auf das
                            Spektrum sozialer Aspekte und Persönlichkeitsentwicklung.</p>

                        <blockquote><p>"Kinder und Jugendliche mit schweren Hautkrankheiten sind in ihrem Selbstwert
                            stark belastet und der Alltag ist für die Familien sehr erschwert. Ich freue mich, den
                            Startschuss für das Hilfsprojekt BesonderHaut geben zu dürfen."</p>
                            <cite>Veronica Ferres</cite>
                        </blockquote>
                        <p>Mit ihrem Projekt BesonderHaut möchte Prof. Kathrin Giehl minderjährigen Patienten mit
                            schweren, teils genetisch bedingten Hautkrankheiten und ihren Familien unbürokratische Hilfe
                            zukommen lassen, denn viele von ihnen werden von ihrer Umwelt aus Unwissenheit und Angst vor
                            Ansteckung stigmatisiert und ausgegrenzt. Ziel ist u.a. die Verbesserung der Inklusion in
                            die Gesellschaft und die Unterstützung bei der Persönlichkeitsentwicklung der Kinder und
                            Jugendlichen. „Wenn seinem Kind eine sehr seltene Hautkrankheit diagnostiziert wird, ist man
                            als Eltern völlig verzweifelt, weil es so gut wie keine Erfahrungswerte und Referenzfälle
                            gibt“ so Christina Jäger. Deshalb setzen wir uns für den Aufbau von Patientenregistern ein.
                            Dadurch können neue Erkenntnisse über die jeweiligen Krankheiten gesammelt werden“ erklärt
                            Prof. Kathrin Giehl. Außerdem werden sich betroffene Familien auf einer Patientenplattform
                            austauschen und damit gegenseitig helfen können.</p>
                        <p><a href="http://www.besonderhaut.de/"><Icon name={"external"}/>Weitere Informationen</a></p>
                    </Container>
                </DefaultSegment>
            </Container>
        </Container>
        <Footer/>
    </MobileTabletContainer>
    <DesktopContainer>
        <div className={layout.pageWrapper}>
            <MainNavigation/>
            <Container>
                <PageHeader title={'BesonderHaut'}/>
                <Container fluid>
                    <Breadcrumb>
                        <Breadcrumb.Section><Link to={'/'}><Icon name={'home'}/>Start</Link></Breadcrumb.Section>
                        <Breadcrumb.Divider/>
                        <Breadcrumb.Section><Link to={'/was-wir-tun/'}>Was wir tun</Link></Breadcrumb.Section>
                        <Breadcrumb.Divider/>
                        <Breadcrumb.Section active>BesonderHaut</Breadcrumb.Section>
                    </Breadcrumb>
                    <div style={{marginBottom: "2rem", marginTop: "1rem"}}>
                        <DefaultSegment>
                            <Container>
                                <Image src={groupPic} centered/>
                                <p>Am 05.09.2017 gab die Schauspielerin Veronica Ferres gemeinsam mit dem Vorstand der
                                    DSK, Frau Dr. Nicole Inselkammer, sowie Prof. Dr. Kathrin Giehl, Oberärztin und
                                    Leiterin des Zentrums für seltene und genetische Hautkrankheiten am Klinikum der
                                    Universität München, den Startschuss für das Hilfsprojekt BesonderHaut. Ebenfalls
                                    dabei war Christina Jäger, neben Prof. Giehl Co-Initiatorin des Projektes.</p>
                                <blockquote><p>"Wir wollen Kindern mit seltener Hauterkrankung wieder ein Lächeln
                                    schenken"</p>
                                    <cite>Dr. Nicole Inselkammer, Stiftungsgründerin und Vorstandsvorsitzende der
                                        DSK</cite>
                                </blockquote>
                                <p>Durch die Implementierung von BesonderHaut in die Stiftungsarbeit erweitert die DSK
                                    die Unterstützung von Kindern mit seltenen und genetischen Hautkrankheiten nun auch
                                    auf das Spektrum sozialer Aspekte und Persönlichkeitsentwicklung.</p>

                                <blockquote><p>"Kinder und Jugendliche mit schweren Hautkrankheiten sind in ihrem
                                    Selbstwert stark belastet und der Alltag ist für die Familien sehr erschwert. Ich
                                    freue mich, den Startschuss für das Hilfsprojekt BesonderHaut geben zu dürfen."</p>
                                    <cite>Veronica Ferres</cite>
                                </blockquote>
                                <p>Mit ihrem Projekt BesonderHaut möchte Prof. Kathrin Giehl minderjährigen Patienten
                                    mit schweren, teils genetisch bedingten Hautkrankheiten und ihren Familien
                                    unbürokratische Hilfe zukommen lassen, denn viele von ihnen werden von ihrer Umwelt
                                    aus Unwissenheit und Angst vor Ansteckung stigmatisiert und ausgegrenzt. Ziel ist
                                    u.a. die Verbesserung der Inklusion in die Gesellschaft und die Unterstützung bei
                                    der Persönlichkeitsentwicklung der Kinder und Jugendlichen. „Wenn seinem Kind eine
                                    sehr seltene Hautkrankheit diagnostiziert wird, ist man als Eltern völlig
                                    verzweifelt, weil es so gut wie keine Erfahrungswerte und Referenzfälle gibt“ so
                                    Christina Jäger. Deshalb setzen wir uns für den Aufbau von Patientenregistern ein.
                                    Dadurch können neue Erkenntnisse über die jeweiligen Krankheiten gesammelt werden“
                                    erklärt Prof. Kathrin Giehl. Außerdem werden sich betroffene Familien auf einer
                                    Patientenplattform austauschen und damit gegenseitig helfen können.</p>
                                <p><a href="http://www.besonderhaut.de/"><Icon name={"external"}/>Weitere Informationen</a>
                                </p>
                            </Container>
                        </DefaultSegment>
                    </div>
                </Container>
            </Container>
            <Footer/>
        </div>
    </DesktopContainer>


</LayoutContainer>